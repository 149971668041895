const de = {
  Navbar: {
    selfcheckout_subheader: 'Self-Checkout',
    dashboard: 'Übersicht',
    restaurant_products: 'Produkte',
    menus: 'Menüs',
    reports: 'Berichte',
    product_tools: 'Produkt-Tools',
    api_sandbox: 'API Sandbox',
    tools_subheader: 'Werkzeuge',
    correction: 'Berichtigung',
    merge: 'Zusammenführen',
    edit: 'Editieren',
    create: 'Erstellen',
    annotations: 'Anmerkungen',
    admin_subheader: 'Administration',
    users: 'Benutzer',
    list: 'Liste',
    restaurants: 'Restaurants',
    itentification_token: 'Identifizierung (Token)',
    visualization: 'Visualisierung',
    clients: 'Kunden',
  },
  RestaurantProductList: {
    Restaurant_Product_List_Title: 'Restaurant Produkte',
    Restaurant_Product_List_Heading: 'Restaurant Produkte',
    New_Product: 'Produkt hinzufügen',
    Products_Deleted_Successfully: 'Produkte erfolgreich gelöscht',
    Product_Added_To: 'Products added to the menu of ',
    Product_Name: 'Prodkt Name',
    Cash_Sys_Code: 'Cash System Code',
    Is_Recurrent: 'Wiederholt sich',
    Is_Industrial: 'Industriell',
    Is_Returned: 'Zurückgegeben',
    Object_Class: 'Art',
    TableToolbar: {
      Search_Product: 'Produkt suchen ...',
    },
    Choose_Date_Menu: 'Datum für das Menü auswählen',
    Add_To_Menu: 'Zum Menü hinzufügen',
    Delete_Products: 'Produkte löschen',
    Dense: 'Dichte',
    Rows_Per_Page: 'Produkte pro Seite',
    There_Is_Only: 'Achtung, es gibt nur',
    Excluded_From_Analysis: 'Dieses Produkt ist von der Analyse ausgeschlossen',
    Image: 'Bild',
    Must_Provide: 'für dieses produkt. Sie müssen mindestens 5 Bilder bereitstellen und sich an Trayvisor wenden.',
    Set_Is_Recurrent: 'In ein wiederkehrendes / nicht wiederkehrendes produkt umwandeln.',
    Cancel: 'Abbrechen',
    True: 'Wahr',
    False: 'Falsch',
    Product: 'Produkt',
    Original_Product: 'Originalprodukt',
    Is_Recurrent_Change: 'Status des wiederkehrenden Produkts geändert!',
  },
  RestaurantProductCreate: {
    Restaurant_Product_Create_Title: 'Produkte: Neues Produkt',
    Restaurant_Product_Create_Heading_Add: 'Neues Produkt anlegen',
    Restaurant_Product_Create_Heading_Edit: 'Produkt editieren',
    NewEditForm: {
      Restaurant_Products: 'Produkte',
      New_Product: 'Produkt hinzufügen',
      Filter_By_Object_Class: 'Filter nach Art des Objekts',
      Product: 'Produkt',
      Choose_Product: 'Produkt auswählen',
      Image_Example_Product: 'Produkt Beispielbild',
      Product_Required: 'Produkt ist erforderlich',
      Product_Name_Required: 'Produkt name ist erforderlich',
      CashSysCode_Required: 'Cash system code ist erforderlich',
      Product_Added_Success: 'Produkt erfolgreich zu Ihrem Restaurant hinzugefügt!',
      Product_Update_Success: 'Update erfolgreich!',
      Set_Up_Product: 'Produkt erstellen',
      Product_Name: 'Produkt Name',
      CashSysCode: 'Cash system Code',
      Is_Recurrent: 'Wiederholt sich',
      Is_Recurrent_Info: 'Sich wiederholende Produkte werden automatisch dem täglichen Menü hinzugefügt',
      Is_Returned: 'Zurückgegeben',
      Is_Returned_Info: 'Nicht zurückgegebene Produkte werden von den Analyseergebnissen ausgeschlossen',
      Add_Product: ' Produkt hinzufügen',
      Save_Changes: 'Änderungen speichern',
    },
  },
  ProductGeneral: {
    Product_Title: 'Produkte',
    Name: 'Produktname',
    Label: 'Produktbezeichnung',
    Description: 'Produktbeschreibung',
    Object_Class: 'Objektklasse (Erkennung)',
    Kind: 'Art',
    Family: 'Familie',
    Brand: 'Marke',
    Food_class: 'Lebensmittelklasse',
    Is_Industrial: 'Industriell',
    Is_Content: 'Container',
  },
  ProductList: {
    Product_List_Heading: 'Produkte',
    Product: 'Produkt',
    List: 'Liste',
    Add_Product: 'Produkt hinzufügen',
    Merge_Products: 'Produkte zusammenführen',
    TableHead: {
      Id: 'ID',
    },
    TableToolbar: {
      Search_Product: 'Produkt suchen...',
    },
    Dense: 'Dicht',
    Rows_Per_Page: 'Produkte pro Seite',
  },
  ProductCreate: {
    Product_Heading_Add: 'Ein neues Produkt hinzufügen',
    Product_Heading_Edit: 'Ein Produkt bearbeiten',
    New_Product: 'Neues Produkt',
    Set_Up_Product: 'Neues Produkt einrichten',
    Product_Name_Required: 'Produktname erforderlich',
    Description_Required: 'Beschreibung erforderlich',
    Label_Required: 'Etikett erforderlich',
    Is_Indus_Required: 'Industriell erforderlich',
    Is_Content_Required: 'Inhalt erforderlich',
    Kind_Required: 'Art erforderlich',
    Family_Required: 'Familie erforderlich',
    Brand_Required: 'Marke erforderlich',
    Object_Class_Required: 'Objektklasse erforderlich',
    Images_Required: 'Mindestens 1 Bild erforderlich',
    Product_Added_Success: 'Produkt erfolgreich hinzugefügt!',
    Branch_Added_Success: 'Marke erfolgreich hinzugefügt!',
    Food_Class_Added_Success: 'Lebensmittelklasse erfolgreich hinzugefügt!',
    Product_Update_Success: 'Aktualisierung erfolgreich!',
    Add_Product: 'Produkt hinzufügen',
    Save_Changes: 'Änderungen speichern',
    Add_Brand: 'Marke hinzufügen',
    Add_New_Brand: 'Neue Marke hinzufügen',
    Brand_Name: 'Markenname',
    Add_Food_Class: 'Lebensmittelklasse hinzufügen',
    Add_New_Food_Class: 'Neue Lebensmittelklasse hinzufügen',
    Enrolled_By: 'Registriert von',
    Is_Industrial_Info: 'Industrieprodukte sind verpackte Produkte.',
    Is_Content_Info: 'Für die Erkennung durch den Behälter (Teller).',
    Image_Example_Product: 'Bildbeispiel',
    NutritionCard: {
      Set_Up_Nutrition_Card: 'Ernährungsinformationen einrichten',
      Calcium: 'Kalzium',
      Cho: 'KH (Kohlenhydrate)',
      Diet_fibre: 'Ballaststoffe',
      Diet_Iron: 'Eisen',
      Gross_Weight: 'Bruttogewicht',
      Kcal: 'Kcal',
      Net_Weight: 'Nettogewicht',
      Protein: 'Protein',
      Sat_Fat: 'Gesättigte Fettsäuren',
      Sodium: 'Natrium',
      Sugars: 'Zucker',
      Total_Fat: 'Gesamtfett',
    },
  },
  Products: {
    ClassOptions: {
      Class_All: 'Alle',
      Class_Indus_Food: 'Industriell',
      Class_Fruit: 'Früchte',
      Class_Drink: 'Getränke',
      Class_Dessert_Starter: 'Vorpseise/Dessert',
      Class_Dish: 'Gericht',
      Class_Bread: 'Brot',
      Class_Unknown: 'Unbekannt',
      Class_Others: 'Andere',
    },
  },
  Menus: {
    Menus_Title: 'Menüs',
    Menus_Header: 'Menüs',
    Menu_Date: 'Menüdatum',
    Menus_Card_Heading: 'Menus_Card_Heading', // to be translated
    Add_Products: 'Produkte zum Menü hinzufügen',
    Delete_From_Menu: 'Vom Menü löschen',
    Display_Menu_History: 'Menühistorie anzeigen',
    Last_Menu_Received_at: 'Letztes Menü erhalten am',
    No_Menu_Received_Today: 'Kein Menü heute erhalten',
    Menu_History: 'Menühistorie',
  },
  Visualization: {
    Dashboard: 'Übersicht',
    Visualization: 'Visualisieren',
    Enrollment: 'Einschreibung',
    Analysis: 'Analyse',
    No_Enrollment_Found: 'Keine Einschreibung gefunden für',
  },
  Others: {
    All: 'Alle',
    True: 'Ja',
    False: 'Nein',
    Cancel: 'Stornieren',
    Delete: 'Löschen',
    Edit: 'Editieren',
  },
  IdentificationToken: {
    Generate_Token_First: 'Bitte zuerst ein Token generieren',
    Token_Copied: 'Token in die Ablage kopiert',
    Identification_Title: 'Identifikation (Token)',
    Identification_Heading: 'Identifikations-Token',
    Admin: 'Admin',
    Expiration_Time: 'Verfallszeit (Tage)',
    Generate_Token: 'Identifikations-Token erstellen',
    Click_To_Copy: 'Click um zui kopieren',
    Click_To_Download: 'Click um herunterzuladen',
  },
  Selectors: {
    UsersSelector: 'Benutzer auswählen',
  },
  LanguageSelector: {
    Languages: 'Languages',
    en: 'Englisch',
    de: 'Deutsch',
    fr: 'Französisch',
  },
  ResetPassword: {
    Password_No_Match: 'Passwort stimmt nich überein',
    Password_Too_Short: 'Mindestens 8 Zeichen',
    Success: 'Erfolgreich',
    Invalid_Token: 'Ungültiges Token',
    Reset_Password: 'Passwort zurücksetzen',
    Password: 'Passwort',
    Confirm_Password: 'Passwort bestätigen',
    Confirm: 'Bestätigen',
  },
  Login: {
    Invalid_Login: 'Falscher Benutzername oder Passwort.',
    Successful_Login: 'Login erfolgreich',
    Login_Instructions: 'Gültigen Benutzernamen und Passwort eingeben.',
    Username: 'Email',
    Enter_Username: 'Benutzername eingeben',
    Password: 'Passwort',
    Enter_Password: 'Passwort eingeben',
    Connect: 'Anmeldung',
    Email_Must_Valid: 'Email muss eine gültige Email Adresse sein',
    Email_Required: 'Email ist erforderlich',
    Password_Required: 'Passwort ist erforderlich',
    Remember_Me: 'Anmeldedaten merken',
  },
  Reporting: {
    Reporting_Title: 'Berichte: Täglich',
    Reporting_Heading: 'Tägliche Berichte',
    Reporting: 'Berichterstattung',
    Daily: 'Täglich',
    Total_Trays: 'Gesamtzahl der Tabletts',
    Total_Tickets: 'Gesamtzahl der Tickets',
    Wrong_Trays: 'Falsche Tabletts',
    Perc_Of_Good_Trays: '% der guten Tabletts (netto)',
    Perc_Of_Good_Trays_Live: '% der guten Tabletts (brutto)',
    Product_Name: 'Produktname',
    Object_Class: 'Objektklasse (Erkennung)',
    Precision: 'Präzision',
    Recall: 'Rückruf',
    F1_Score: 'F1-Wert',
    TP_Description: 'Richtig Positiv:\nAnzahl der korrekt identifizierten Artikel.',
    FP_Description:
      'Falsch Positiv:\nAnzahl der falsch identifizierten Artikel. Wir haben vorhergesagt, dass der Artikel vorhanden war, aber er war es nicht.',
    FN_Description:
      'Falsch Negativ:\nAnzahl der nicht identifizierten Artikel. Wir haben vorhergesagt, dass der Artikel nicht vorhanden war, aber er war es.',
    Precision_Description: 'Präzision: Wie viel der vorhergesagten Artikel tatsächlich vorhanden sind.',
    Recall_Description: 'Rückruf: Wie viel der tatsächlichen Artikel vorhergesagt wurden.',
    F1_Score_Description: 'F1-Wert: Das harmonische Mittel aus Präzision und Rückruf.',
    Statistics_Per_Product: 'Statistiken pro Produkt',
    ObjectClasses_To_Remove: 'Objektklassen zum Entfernen',
  },
  Components: {
    BlockContent: {
      Drop_Or_Select_File: 'Datei ablegen oder auswählen',
      Drop_File_Or_Click: 'Dateien hier ablegen oder klicken',
      Browse: 'durchsuchen',
      Thorough_Your_Machine: 'Ihren Computer durchsuchen',
    },
    DeleteDialog: {
      Are_You_Sure: 'Sind Sie sicher, dass Sie ',
      Product: 'Produkt',
      Action_Cannot_Undone: 'Diese Aktion kann nicht rückgängig gemacht werden.',
    },
    MergeDialog: {
      Are_You_Sure: 'Sind Sie sicher, dass Sie ',
      Product: 'Produkt',
      Action_Cannot_Undone: 'Diese Aktion kann nicht rückgängig gemacht werden!',
      Merge: 'Zusammenführen',
    },
  },
  Clients: {
    Clients_Title: 'Kunden',
    Clients_Heading_List: 'Kunden Liste',
    New_Client: 'Neuer Kunden',
    TableToolbar: {
      Search_Client: 'Kunden suchen...',
    },
    TableHead: {
      Id: 'ID',
      ClientHash: 'Kunden Hash',
      Restaurant: 'Restaurant',
      Status: 'Status',
    },
    Form: {
      ClientHash: 'Kunden Hash',
      ClientHash_Required: 'Kunden Hash ist erforderlich',
      ActiveStatus: 'Aktiv Status',
      Status_Required: 'Status ist erforderlich',
      Create_Client: 'Kunden erstellen',
      Save_Changes: 'Änderungen speichern',
      Client_Created_Success: 'Kunde erfolgreich erstellt!',
      Client_Updated_Success: 'Kunde erfolgreich aktualisiert!',
    },
  },
};

export default de;
