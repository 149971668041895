import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // Once the dashboard as been created, the element should become <GeneralApp> again

        // --- General --- //
        { path: 'app', element: <DashboardLayout /> },
        {
          path: 'restaurant_product',
          children: [
            {
              element: <Navigate to="/dashboard/restaurant_product/list" replace />,
              index: true,
            },
            { path: 'list', element: <RestaurantProductList /> },
            { path: 'card', element: <RestaurantProductCard /> },
            { path: 'new', element: <RestaurantProductCreate /> },
            { path: ':id/edit', element: <RestaurantProductCreate /> },
          ],
        },
        {
          path: 'menus',
          children: [
            {
              element: <Navigate to="/dashboard/menus/list" replace />,
              index: true,
            },
            { path: 'list', element: <MenusList /> },
            { path: 'card', element: <MenusCard /> },
          ],
        },
        {
          path: 'visualization',
          children: [
            {
              element: <Navigate to="/dashboard/visualization/analysis" replace />,
              index: true,
            },
            { path: 'analysis', element: <VisualizeAnnotation /> },
            { path: 'enrollment', element: <VisualizeEnroll /> },
          ],
        },
        {
          path: 'reporting',
          children: [
            {
              element: <Navigate to="/dashboard/reporting/daily" replace />,
              index: true,
            },
            { path: 'daily', element: <ReportingDaily /> },
          ],
        },

        // --- Health --- //
        {
          path: 'health_visualization',
          children: [
            {
              element: <Navigate to="/dashboard/health_visualization/analysis" replace />,
              index: true,
            },
            { path: 'analysis', element: <VisualizeHealthAnnotation /> },
          ],
        },
        {
          path: 'clients',
          children: [
            {
              element: <Navigate to="/dashboard/clients/list" replace />,
              index: true,
            },
            { path: 'list', element: <Clients /> },
          ],
        },

        // --- Tools --- //
        {
          path: 'product',
          children: [
            {
              element: <Navigate to="/dashboard/product/list" replace />,
              index: true,
            },
            { path: 'list', element: <ProductList /> },
            { path: 'new', element: <ProductCreate /> },
            { path: ':id/edit', element: <ProductCreate /> },
            { path: ':id/edit/merged', element: <ProductCreate /> },
            { path: ':ids/merge', element: <ProductMerge /> },
          ],
        },
        {
          path: 'annotations',
          children: [
            {
              element: <Navigate to="/dashboard/annotations/images" replace />,
              index: true,
            },
            { path: 'images', element: <AnnotationsImagesBbox /> },
            {
              path: 'images/direct_link/:annotation',
              element: <AnnotationsImagesBbox />,
            },
            { path: 'check', element: <AnnotationsImagesCheck /> },
            { path: 'crop', element: <AnnotationsCrop /> },
          ],
        },
        { path: 'api-sandbox', element: <APISandbox /> },
        { path: 'crop', element: <AnnotationsCrop /> },
        {
          path: 'models',
          children: [
            {
              element: <Navigate to="/dashboard/models/feature-clf" replace />,
              index: true,
            },
            { path: 'feature-clf', element: <ModelFeatureClfDetails /> },
          ],
        },
        { path: 'doc', element: <ApiDocumentation /> },

        // --- Admin --- //
        {
          path: 'restaurant',
          children: [
            {
              element: <Navigate to="/dashboard/restaurant/list" replace />,
              index: true,
            },
            { path: 'list', element: <RestaurantList /> },
            { path: 'new', element: <RestaurantCreate /> },
            { path: ':id/edit', element: <RestaurantCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/list" replace />,
              index: true,
            },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        { path: 'identification-token', element: <IdentificationToken /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// RESTAURANT PRODUCTS
const RestaurantProductList = Loadable(lazy(() => import('../pages/dashboard/RestaurantProducts')));
const RestaurantProductCard = Loadable(lazy(() => import('../pages/dashboard/RestaurantProductsCard')));
const RestaurantProductCreate = Loadable(lazy(() => import('../pages/dashboard/RestaurantProductCreate')));
const MenusList = Loadable(lazy(() => import('../pages/dashboard/GeneralMenus')));
const MenusCard = Loadable(lazy(() => import('../pages/dashboard/MenusCard')));
const ReportingDaily = Loadable(lazy(() => import('../pages/dashboard/ReportingDaily')));

// TOOLS
const VisualizeAnnotation = Loadable(lazy(() => import('../pages/dashboard/VisualizeAnnotation')));
const VisualizeEnroll = Loadable(lazy(() => import('../pages/dashboard/VisualizeEnroll')));
const AnnotationsImagesBbox = Loadable(lazy(() => import('../pages/dashboard/GeneralAnnotationTool')));
const AnnotationsImagesCheck = Loadable(lazy(() => import('../pages/dashboard/AnnotationsImagesCheck')));
const AnnotationsCrop = Loadable(lazy(() => import('../pages/dashboard/AnnotationsCropTool')));
const APISandbox = Loadable(lazy(() => import('../pages/dashboard/APISandbox')));
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ProductMerge = Loadable(lazy(() => import('../pages/dashboard/ProductMerge')));
const ModelFeatureClfDetails = Loadable(lazy(() => import('../pages/dashboard/ModelFeatureClfDetails')));
const ApiDocumentation = Loadable(lazy(() => import('../pages/dashboard/ApiDocumentation')));

// HEALTH
const VisualizeHealthAnnotation = Loadable(lazy(() => import('../pages/dashboard/VisualizeHealthAnnotation')));
const Clients = Loadable(lazy(() => import('../pages/dashboard/Clients')));

// ADMIN
const IdentificationToken = Loadable(lazy(() => import('../pages/dashboard/IdentificationTokenCreate')));
const RestaurantList = Loadable(lazy(() => import('../pages/dashboard/RestaurantList')));
const RestaurantCreate = Loadable(lazy(() => import('../pages/dashboard/RestaurantCreate')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// OTHER
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
