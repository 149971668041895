// ----------------------------------------------------------------------

export const path = (root, sublink) => {
  return `${root}${sublink}`;
};

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  test: '/test',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // GENERAL
  app: path(ROOTS_DASHBOARD, '/app'),
  restaurantProduct: {
    root: path(ROOTS_DASHBOARD, '/restaurant_product'),
    list: path(ROOTS_DASHBOARD, '/restaurant_product/list'),
    card: path(ROOTS_DASHBOARD, '/restaurant_product/card'),
    new: path(ROOTS_DASHBOARD, '/restaurant_product/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/restaurant_product/${id}/edit`),
  },
  menus: {
    root: path(ROOTS_DASHBOARD, '/menus'),
    list: path(ROOTS_DASHBOARD, '/menus/list'),
    card: path(ROOTS_DASHBOARD, '/menus/card'),
  },
  visualization: {
    root: path(ROOTS_DASHBOARD, '/visualization'),
    analysis: path(ROOTS_DASHBOARD, '/visualization/analysis'),
    enrollment: path(ROOTS_DASHBOARD, '/visualization/enrollment'),
  },
  reporting: {
    root: path(ROOTS_DASHBOARD, '/reporting'),
    daily: path(ROOTS_DASHBOARD, '/reporting/daily'),
  },
  
  // HEALTH
  health_visualization: {
    root: path(ROOTS_DASHBOARD, '/health_visualization'),
    analysis: path(ROOTS_DASHBOARD, '/health_visualization/analysis'),
  },
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    list: path(ROOTS_DASHBOARD, '/clients/list'),
  },

  // TOOLS
  annotations: {
    root: path(ROOTS_DASHBOARD, '/annotations'),
    images: path(ROOTS_DASHBOARD, '/annotations/images'),
    direct_link: (link) => path(ROOTS_DASHBOARD, `/annotations/images/direct_link/${link}`),
    check: path(ROOTS_DASHBOARD, '/annotations/check'),
    crop: path(ROOTS_DASHBOARD, '/annotations/crop'),
  },
  apiSandbox: path(ROOTS_DASHBOARD, '/api-sandbox'),
  crop: path(ROOTS_DASHBOARD, '/crop'),
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
    editMerged: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit/merged`),
    merge: (ids) => path(ROOTS_DASHBOARD, `/product/${ids}/merge`),
  },
  models: {
    root: path(ROOTS_DASHBOARD, '/models'),
    featureClassifier: path(ROOTS_DASHBOARD, '/models/feature-clf'),
  },
  apiDocumentation: path(ROOTS_DASHBOARD, '/doc'),
  // ADMIN
  restaurant: {
    root: path(ROOTS_DASHBOARD, '/restaurant'),
    new: path(ROOTS_DASHBOARD, '/restaurant/new'),
    list: path(ROOTS_DASHBOARD, '/restaurant/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/restaurant/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  },
  identificationToken: path(ROOTS_DASHBOARD, '/identification-token'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
